import {
  faCheckCircle,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Skeleton,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import util from "../../../utils/util";
import UpdateLogoModal from "../../components/UpdateLogoModal";
import { saveOrganization } from "../../features/organization";
import OrganizationService from "../../services/organizationService";
import AgreementImage from "../../../assets/images/iStock-1390529500.jpg";
import useApplyAllModal from "../../hooks/useApplyAllModal";

/**
 * A functional component that renders the organization profile page.
 * @returns The organization profile page UI.
 */
const OrgProfile = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [style, setStyle] = useState({ display: "none" });
  const [open, setOpen] = useState(false);
  const [premiumChecked, setPremiumChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeRevert, setActiveRevert] = useState(false);
  let organizationData = useSelector((state) => state.organization);
  const [logo, setLogo] = useState();
  const [confirm, contextHolder] = useApplyAllModal(form, organizationData);
  const [wordCount, setWordCount] = useState(0);

  const handleTextAreaChange = (e) => {
    const { value } = e.target;
    let words = [];
    if (value !== "") {
      words = value.trim().split(/\s+/);
    }
    if (words.length === 200 && value.charAt(value.length - 1) === " ") {
      setWordCount(words.length + 1);
    } else {
      setWordCount(words.length);
    }
  };

  /*
  validates url for website url input field
  */

  const validateUrl = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }

    // Regular expression to validate URL with optional protocol and www
    const urlRegex =
      /^(?:(?:https?|ftp):\/\/)?(?:www\.)?([^\s/$.?#].[^\s]*\.[^\s]{2,})$/i;

    if (urlRegex.test(value.replace("www.", "").replace(".www", ""))) {
      return Promise.resolve();
    }

    return Promise.reject(new Error("Please enter a valid Website URL"));
  };

  /*
  validates word limit for description
  */
  const validateWordLimit = (_, value) => {
    if (
      value &&
      value.trim().split(/\s+/).length === 200 &&
      value.charAt(value.length - 1) === " "
    ) {
      return Promise.reject("Maximum word limit exceeded (200 words).");
    }
    if (value && value.trim().split(/\s+/).length > 200) {
      return Promise.reject("Maximum word limit exceeded (200 words).");
    }
    return Promise.resolve();
  };

  /**
   * Accepts the premium service for the current organization.
   * If successful, updates the organization data in the store.
   * If unsuccessful, displays an error notification.
   */
  const acceptPremium = () => {
    OrganizationService.acceptService(organizationData.organizationId)
      .then((res) => {
        let orgData = Object.assign({}, organizationData);
        orgData.serviceOpted = 1;
        dispatch(saveOrganization(orgData));
      })
      .catch(() => {
        notification.error({
          message: "Something went wrong !!! Please refresh the page ..",
        });
      });
  };

  /**
   * Saves the organization data to the server and updates the state with the new data.
   * @param {{Object}} values - The new values to save to the organization profile.
   */
  const saveOrgData = (values) => {
    setLoading(true);
    let payload = util.getOrgProfilePayload(values, organizationData);
    OrganizationService.saveOrganizationDetails(payload)
      .then(() => {
        dispatch(saveOrganization(payload));
        setLoading(false);
        window.location.href = "/dashboard";
      })
      .catch(() => {
        setLoading(false);
        notification.error({
          message: "Error while saving Organization profile !!!",
        });
      });
  };

  /**
   * Uploads an image to the server and updates the organization logo.
   * @param {{fmData}} fmData - The image data to upload.
   * @returns A promise that resolves with the updated organization data.
   * @throws An error if the upload fails.
   */
  const uploadImage = async (fmData) => {
    return await OrganizationService.uploadLogo(fmData)
      .then((res) => {
        let refreshData = Object.assign({}, res.data.data);
        refreshData.organizationLogo = null;
        dispatch(saveOrganization(refreshData));
        dispatch(saveOrganization(res.data.data));
      })
      .catch(() => {
        notification.error({ message: "Uploading logo failed !!!" });
      });
  };

  /**
   * Deletes the logo of the organization by calling the deleteLogo method of the OrganizationService.
   * If the deletion is successful, the organizationData is updated to remove the logo and the updated data is saved.
   * If the deletion fails, an error notification is displayed.
   */
  const deleteImage = async () => {
    return await OrganizationService.deleteLogo()
      .then((res) => {
        let refreshData = Object.assign({}, organizationData);
        refreshData.organizationLogo = null;
        dispatch(saveOrganization(refreshData));
      })
      .catch(() => {
        notification.error({ message: "Deleting logo failed !!!" });
      });
  };
  /**
   * Handles the revert functionality for the organization profile form.
   * This function retrieves the original form data for the organization and sets the form fields to those values.
   * It then sets the active revert state to false.
   */
  const handleRevert = () => {
    let formData = util.getOrgProfileFormData(organizationData);
    form.setFields(formData);
    setActiveRevert(false);
  };
  /**
   * useEffect hook that sets the form fields to the organization data and fetches the logo
   * if it exists. If the organization has opted for premium service, the premium checkbox is checked.
   * @param {{Object}} organizationData - the organization data object
   * @returns None
   */
  useEffect(() => {
    let formData = util.getOrgProfileFormData(organizationData);
    form.setFields(formData);
    if (organizationData.length !== 0) {
      organizationData.organizationDescription &&
        setWordCount(
          organizationData.organizationDescription.trim().split(/\s+/).length,
        );
      OrganizationService.fetchLogo(organizationData.organizationId).then(
        (res) => {
          setLogo(res.data.data);
        },
      );
    }
    if (organizationData.serviceOpted === 1) {
      setPremiumChecked(true);
    }
  }, [organizationData]);
  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        footer={null}
        onCancel={() => {
          setOpen(false);
        }}
        style={{
          top: 40,
        }}
        width={700}>
        <div className="agreement-header">More ways to find connections</div>
        <div className="agreement-body">
          <div className="agreement-body-text-modal">
            <div className="agreement-img">
              <img src={AgreementImage} alt="AgreementImage" />
            </div>
            <div
              // dangerouslySetInnerHTML={{ __html: terms }}
              className="premium-body-text">
              CRIO may be asked by sponsors or CROs to conduct premium searches
              using access to information within our system that is not
              available on CRIO Connect.{" "}
              <span>Do you give CRIO permission</span>
              <i>
                {" "}
                to mine your data for purposes of recommending sites on these
                premium searches?
              </i>
              <br />
              <br />
              <Row>
                <Col span={2}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="#138639"
                    fontSize={22}
                  />
                </Col>
                <Col span={22}>
                  CRIO will never share your CRIO data directly with sponsors or
                  CROs; instead, CRIO will indicate whether the premium search
                  yielded likely compatibility against pre-specified target
                  criteria (e.g., experience with a certain class of
                  investigational drug, or exposure to a certain scale).
                </Col>
              </Row>
              <br />
              <Row>
                <Col span={2}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="#138639"
                    fontSize={22}
                  />
                </Col>
                <Col span={22}>
                  CRIO recognizes that data within your CRIO system may not be
                  complete, so CRIO will never disqualify a site based on this
                  premium search, nor will CRIO disclose to the sponsor or CRO
                  which specific sites CRIO data mined. CRIO will only be
                  providing names of sites who appear to meet the target
                  criteria and will caveat that the list is suggestive and not
                  comprehensive. Furthermore, CRIO will never use access to your
                  data to qualify or disqualify a statement you have made to a
                  sponsor or CRO.
                </Col>
              </Row>
              <br />
              <Row>
                <Col span={2}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="#138639"
                    fontSize={22}
                  />
                </Col>
                <Col span={22}>
                  Accordingly, there is very little risk to opting in, and there
                  is the potential to be proactively advanced by CRIO for
                  consideration on a given study.
                </Col>
              </Row>
              <br />
              <br />
            </div>
          </div>
        </div>
      </Modal>
      <div className="body-container">
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
            <div className="op">
              <div className="op-left-title">Organization Profile</div>
              <div className="op-p">
                The prefilled fields have all been imported from CRIO. Should
                any changes need to be made, please update the information
                accordingly.
              </div>
              <br />
              <br />
              <div className="op-p">
                **The changes you make in CRIO Connect will not affect your
                information in the CRIO site app.
              </div>
              <Divider
                dashed
                style={{ margin: "50px 0", borderColor: "#CCC" }}
              />
              <div className="org-premium-title">
                {organizationData.serviceOpted
                  ? "You have successfully opted in to CRIO’s premium search"
                  : "More ways to find connections"}
                <span
                  onClick={() => {
                    setOpen(true);
                  }}>
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    fontSize={22}
                    color="#009ceb"
                  />
                </span>
              </div>
              {organizationData.serviceOpted ? (
                <div className="premium-p">
                  If you want to opt out of the premium search, please contact
                  CRIO.
                </div>
              ) : (
                <>
                  <Checkbox
                    checked={premiumChecked}
                    onChange={(e) => {
                      setPremiumChecked(e.target.checked);
                    }}
                    className="agreement-footer-text">
                    Yes, I would like to opt in for CRIO’s premium search. By
                    opting in, you will let CRIO use your data for referral to
                    Sponsors/CROs.
                  </Checkbox>
                  <Button
                    type="primary"
                    disabled={!premiumChecked}
                    className="agreement-footer-button  agreement-footer-button-org"
                    onClick={acceptPremium}>
                    Opt in
                  </Button>
                </>
              )}
            </div>
          </Col>
          <Col
            xs={0}
            sm={0}
            md={2}
            lg={2}
            xl={2}
            style={{ textAlign: "center" }}>
            <Divider type="vertical" className="vertical-divider-class" />
          </Col>

          <Col xs={24} sm={24} md={17} lg={17} xl={17}>
            <div className="op-right-header">
              Organization Information
              <p className="op-p">* Indicates a required field</p>
            </div>
            <div className="op">
              <Form
                form={form}
                onFinish={saveOrgData}
                onChange={() => {
                  setActiveRevert(true);
                }}>
                <Row gutter={[24, 24]}>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <div className="op-label">Organization Logo</div>
                    <div className="op-logo-wrapper">
                      <div className="op-logo">
                        {logo ? (
                          <img
                            src={logo}
                            alt="Organization Logo"
                            onMouseEnter={() => {
                              setStyle({
                                display: "flex",
                                margin: "20px 10px",
                                height: "-webkit-fill-available",
                                width: "-webkit-fill-available",
                                color: "white",
                              });
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onMouseEnter={() => {
                              setStyle({
                                display: "flex",
                                margin: "20px 10px",
                                height: "-webkit-fill-available",
                                width: "-webkit-fill-available",
                                color: "white",
                              });
                            }}>
                            <Skeleton.Image />
                          </div>
                        )}
                        <UpdateLogoModal
                          style={style}
                          setStyle={setStyle}
                          uploadLogoData={uploadImage}
                          titleText={"Logo"}
                          deleteImage={deleteImage}
                          deleteOption={
                            organizationData.organizationLogo ? true : false
                          }
                          img={organizationData.organizationLogo ? logo : false}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={14}
                    lg={14}
                    xl={14}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "end",
                    }}>
                    <Form.Item name={"uniformLogo"} valuePropName="checked">
                      <Checkbox
                        className="op-checkbox"
                        onChange={(e) => {
                          confirm("logo", e);
                        }}>
                        Apply this logo to all sites
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <div className="op-label">Organization Name</div>
                  </Col>
                  <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                    <div className="op-label">
                      {organizationData.organizationName}
                    </div>
                  </Col>
                </Row>
                <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <div className="op-label">Public Display Name</div>
                  </Col>
                  <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                    <Form.Item name={"publicDisplayName"}>
                      <Input
                        className="crio-input"
                        placeholder="Enter Public Display Name"
                      />
                    </Form.Item>
                    <p className="op-p-12">
                      *Optional - By entering a name in this field, this display
                      name will be used throughout CRIO Connect
                    </p>
                    <Form.Item
                      name={"uniformDisplayName"}
                      valuePropName="checked">
                      <Checkbox
                        className="op-checkbox"
                        onChange={(e) => {
                          confirm("display name", e);
                        }}>
                        Apply this name to all sites
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <div className="op-label">Organization Website URL</div>
                  </Col>
                  <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                    <Form.Item
                      name={"organizationWebsiteUrl"}
                      rules={[
                        {
                          validator: validateUrl,
                        },
                      ]}>
                      <Input
                        className="crio-input"
                        placeholder="Enter Organization Website URL"
                      />
                    </Form.Item>
                    <Form.Item name={"uniformUrl"} valuePropName="checked">
                      <Checkbox
                        className="op-checkbox"
                        onChange={(e) => {
                          confirm("URL", e);
                        }}>
                        Apply this URL to all sites
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <div className="op-label">Organization Description</div>
                  </Col>
                  <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                    <Form.Item
                      name={"organizationDescription"}
                      rules={[
                        {
                          validator: validateWordLimit,
                        },
                      ]}>
                      <Input.TextArea
                        bordered={false}
                        onChange={handleTextAreaChange}
                        className="crio-input"
                        style={{
                          height: 120,
                          resize: "none",
                          fontSize: "14px",
                          fontFamily: [
                            "Poppins-Light",
                            "Poppins Light",
                            "Poppins",
                          ],
                          fontWeight: "200",
                          color: "#999999 !important",
                        }}
                        placeholder="Enter Description (Max 200 words)"
                      />
                    </Form.Item>
                    <div className="crio-word-count">{wordCount} / 200</div>
                    <Form.Item
                      name={"uniformDescription"}
                      valuePropName="checked">
                      <Checkbox
                        className="op-checkbox"
                        onChange={(e) => {
                          confirm("description", e);
                        }}>
                        Apply this description to all sites
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "10px" }}>
                  <Divider className="horizontal-divider-class" />
                </Row>
                <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <div className="op-right-header">Inquiry Information</div>
                  </Col>
                </Row>
                <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <div className="op-label">
                      * Primary Email Address
                      <p className="op-p" style={{ margin: "5px 17px" }}>
                        * Email for CRIO to send inquiries
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                    <Form.Item
                      name="primaryEmail"
                      rules={[
                        {
                          required: true,
                          message: "Please enter primary email address",
                        },
                        {
                          type: "email",
                          message: "Not a valid email address",
                        },
                      ]}>
                      <Input
                        className="crio-input"
                        placeholder="Enter Primary Email Address"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
              <Col
                xs={24}
                sm={24}
                md={10}
                lg={10}
                xl={10}
                style={{ margin: "30px 0 20px 0" }}>
                <Space>
                  <Form
                    form={form}
                    onFinish={saveOrgData}
                    scrollToFirstError={true}>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        className="warning-footer-left"
                        loading={loading}>
                        Save
                      </Button>
                      <Button
                        type="text"
                        size={"large"}
                        className="warning-footer-right"
                        onClick={() => {
                          handleRevert();
                        }}
                        disabled={!activeRevert}>
                        Revert Changes
                      </Button>
                    </Form.Item>
                  </Form>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OrgProfile;
