import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Row,
  Form,
  Input,
  Select,
  Button,
  Checkbox,
  notification,
  Space,
  Skeleton,
} from "antd";
import UpdateLogoModal from "./UpdateLogoModal";
import OrganizationService from "../services/organizationService";
import { updateSite } from "../features/sites";
import SiteImagesUpload from "./SiteImagesUpload";
import util from "../../utils/util";
import DraftWarningModal from "./DraftWarningModal";
// import ErrorFieldModal from "./ErrorFieldModal";
import { saveDirtyForm } from "../features/dirtyForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
const CheckboxGroup = Checkbox.Group;

// Renders the content for editing a site's data.
function SiteEditContent({
  siteData,
  setActiveTab,
  setSiteActiveKey,
  setEdited,
  showWarning,
  exitTab,
  upcomingTab,
  setShowWarning,
  edited,
  setUpcomingTab,
}) {
  const states = useSelector((state) => state.states);
  const facilityEquipments = useSelector((state) => state.facility);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [logo, setLogo] = useState();
  const [style, setStyle] = useState({ display: "none" });
  const [loading, setLoading] = useState(false);
  const [imgCount, setImgCount] = useState(0);
  const { Option } = Select;
  // const [open, setOpen] = useState(false);
  // const [errors, setErrors] = useState([]);
  const [wordCount, setWordCount] = useState(0);

  const handleTextAreaChange = (e) => {
    const { value } = e.target;
    let words = [];
    if (value !== "") {
      words = value.trim().split(/\s+/);
    }
    if (words.length === 200 && value.charAt(value.length - 1) === " ") {
      setWordCount(words.length + 1);
    } else {
      setWordCount(words.length);
    }
  };

  /*
  validates url for website url input field
  */

  const validateUrl = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }

    // Regular expression to validate URL with optional protocol and www
    const urlRegex =
      /^(?:(?:https?|ftp):\/\/)?(?:www\.)?([^\s/$.?#].[^\s]*\.[^\s]{2,})$/i;

    if (urlRegex.test(value.replace("www.", "").replace(".www", ""))) {
      return Promise.resolve();
    }

    return Promise.reject(new Error("Please enter a valid Website URL"));
  };

  const validateWordLimit = (_, value) => {
    if (
      value &&
      value.trim().split(/\s+/).length === 200 &&
      value.charAt(value.length - 1) === " "
    ) {
      return Promise.reject("Maximum word limit exceeded (200 words).");
    }
    if (value && value.trim().split(/\s+/).length > 200) {
      return Promise.reject("Maximum word limit exceeded (200 words).");
    }
    return Promise.resolve();
  };

  // Handles the submission of the site profile form data to the server.
  const onFinish = (e) => {
    setLoading(true);
    let payload = util.getSaveSitePayload(e, states, siteData);
    OrganizationService.saveSiteInfo(siteData.siteId, payload)
      .then((res) => {
        dispatch(updateSite(res.data.data));
        notification.success({
          message: `Site Profile got saved for ${siteData.siteName}...`,
        });
        setActiveTab(upcomingTab === -1 ? "2" : upcomingTab);
        setEdited(false);
        dispatch(saveDirtyForm(false));
        setLoading(false);
      })
      .catch((err) => {
        notification.error({ message: "Error while saving site profile !!!" });
        setLoading(false);
      });
  };

  /**
   * Uploads an image to the server and updates the site data with the new logo and percentage complete.
   * @param {{fmData}} fmData - The image data to upload.
   */
  const uploadImage = async (fmData) => {
    return await OrganizationService.uploadSiteLogo(siteData.siteId, fmData)
      .then((res) => {
        let refreshData = Object.assign({}, siteData);
        refreshData.siteLogo = res.data.data.siteLogo;
        refreshData.percentageComplete = res.data.data.percentageComplete;
        dispatch(updateSite(refreshData));
      })
      .catch(() => {
        notification.error({ message: "Uploading logo failed !!!" });
      });
  };

  /**
   * Deletes the site logo for the current site.
   * @returns {Promise} A promise that resolves when the logo is successfully deleted.
   * If the deletion fails, an error notification is displayed.
   */
  const deleteSiteLogo = async () => {
    return await OrganizationService.deleteSiteLogo(siteData.siteId)
      .then((res) => {
        let refreshData = Object.assign({}, siteData);
        refreshData.siteLogo = null;
        refreshData.percentageComplete = res.data.data.percentageComplete;
        dispatch(updateSite(refreshData));
      })
      .catch(() => {
        notification.error({ message: "Deleting logo failed !!!" });
      });
  };

  /**
   * Saves the current tab by validating the form fields and calling the onFinish function with the form values.
   * If the upcomingTab is -1, sets the siteActiveKey to -1, otherwise sets the active tab to the upcomingTab.
   * Sets edited to false and showWarning to -1.
   * If there is an error, sets showWarning to -1 and opens the error modal with the error fields.
   * @returns None
   */
  const saveTab = () => {
    form
      .validateFields()
      .then(() => {
        onFinish(form.getFieldsValue());
        if (upcomingTab === -1) {
          setSiteActiveKey(-1);
        } else {
          setActiveTab(upcomingTab);
        }
        setEdited(false);
        dispatch(saveDirtyForm(false));
        setShowWarning(-1);
      })
      .catch((err) => {
        setShowWarning(-1);
        setUpcomingTab(-1);
        form.scrollToField(err.errorFields[0].name);
        // setErrors(err.errorFields);
        // setOpen(true);
      });
  };

  /**
   * useEffect hook that fetches the site logo and sets it to state if it exists in the site data.
   * It also sets the form fields to the site data.
   * @param {{Object}} siteData - The site data object.
   */
  useEffect(() => {
    if (siteData && siteData.siteLogo) {
      OrganizationService.fetchSiteLogo(siteData.siteId).then((res) => {
        setLogo(res.data.data);
      });
    } else {
      setLogo(null);
    }
    if (siteData) {
      siteData.publicSiteDescription &&
        setWordCount(siteData.publicSiteDescription.trim().split(/\s+/).length);
      let formData = util.getSiteFormData(siteData);
      form.setFields(formData);
    }
  }, [siteData]);

  return (
    <>
      <DraftWarningModal
        open={showWarning === "1" ? true : false}
        exitTab={exitTab}
        saveTab={saveTab}
        setShowWarning={setShowWarning}
        setUpcomingTab={setUpcomingTab}
      />
      {/* <ErrorFieldModal open={open} setOpen={setOpen} errors={errors} /> */}
      <Form
        form={form}
        onFinish={onFinish}
        style={{ margin: "20px" }}
        onChange={() => {
          setEdited(true);
          dispatch(saveDirtyForm(true));
        }}
        onFinishFailed={() => {
          let errList = [];
          form.getFieldsError().forEach((el) => {
            if (el.errors.length !== 0) {
              errList.push(el);
            }
          });
          // setErrors(errList);
          // setOpen(true);
        }}
        scrollToFirstError
      >
        <Row gutter={[24, 24]}>
          <Col span={10}>
            <div className="edit-site-form-label">Site Logo</div>
          </Col>
          <Col span={14}>
            <div className="edit-site-logo">
              {logo ? (
                <img
                  src={logo}
                  alt="Site Logo"
                  onMouseEnter={() => {
                    setStyle({
                      display: "flex",
                      margin: "20px 10px",
                      height: "-webkit-fill-available",
                      width: "-webkit-fill-available",
                      color: "white",
                    });
                  }}
                />
              ) : (
                <div
                  onMouseEnter={() => {
                    setStyle({
                      display: "flex",
                      margin: "20px 10px",
                      height: "-webkit-fill-available",
                      width: "-webkit-fill-available",
                      color: "white",
                    });
                  }}
                >
                  <Skeleton.Image />
                </div>
              )}
              <UpdateLogoModal
                style={style}
                setStyle={setStyle}
                uploadLogoData={uploadImage}
                titleText={"Logo"}
                deleteImage={deleteSiteLogo}
                deleteOption={siteData.siteLogo ? true : false}
                img={siteData.siteLogo ? logo : false}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div className="op-label">Site Name</div>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <div className="op-label">{siteData.siteName}</div>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div className="op-label">Public Display Name</div>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <Form.Item name={"publicDisplayName"}>
              <Input className="crio-input" placeholder="Enter Custom Name" />
            </Form.Item>
            <p className="op-p op-p-12" style={{ maxWidth: "90%" }}>
              *Optional - By entering a name in this field, this display name
              will be used throughout CRIO Connect
            </p>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div className="op-label">*Site Location</div>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <Row span={24}>
              <Col span={24}>
                <Form.Item
                  name={"houseAddress"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter Address",
                    },
                  ]}
                >
                  <Input className="crio-input" placeholder="Enter Address" />
                </Form.Item>
              </Col>
            </Row>
            <Row span={24}>
              <Col span={7}>
                <Form.Item
                  name={"country"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter country",
                    },
                  ]}
                >
                  <Input
                    className="crio-input"
                    placeholder="Country"
                    rules={[
                      {
                        required: true,
                        message: "Please enter country",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item>
                  <Input.Group compact>
                    <Form.Item
                      name={"state"}
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: "Please select State",
                        },
                      ]}
                    >
                      <Select
                        placeholder="-Select-"
                        className="crio-input"
                        getPopupContainer={(trigger) => trigger.parentNode}
                        suffixIcon={<FontAwesomeIcon icon={faCaretDown} />}
                        rules={[
                          {
                            required: true,
                            message: "Please select State",
                          },
                        ]}
                        onChange={() => {
                          setEdited(true);
                          dispatch(saveDirtyForm(true));
                        }}
                      >
                        {states.map((st) => {
                          return (
                            <Option value={st.lookupId} key={st.lookupId}>
                              {st.value}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={"city"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter city",
                    },
                  ]}
                >
                  <Input
                    className="crio-input"
                    placeholder="City"
                    style={{ width: "95%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div className="op-label">
              Site Images - 50 images max{" "}
              <span className="img-count-span">{imgCount}</span>
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
          <Col span={24}>
            <SiteImagesUpload siteData={siteData} setImgCount={setImgCount} />
          </Col>
          <Col span={24}>
            <p className="op-p" style={{ maxWidth: "90%", marginLeft: "22px" }}>
              *CRIO has the right to remove photos if they are inappropriate
            </p>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div className="op-label">Site Website URL</div>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <Form.Item
              name={"siteWebsiteUrl"}
              rules={[
                {
                  validator: validateUrl,
                },
              ]}
            >
              <Input className="crio-input" placeholder="Website Url" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div className="op-label">Public Site Description</div>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <Form.Item
              name={"publicSiteDescription"}
              rules={[
                {
                  validator: validateWordLimit,
                },
              ]}
            >
              <Input.TextArea
                bordered={false}
                onChange={handleTextAreaChange}
                className="crio-input"
                style={{
                  height: 120,
                  resize: "none",
                  fontSize: "14px",
                  fontFamily: ["Poppins-Light", "Poppins Light", "Poppins"],
                  fontWeight: "200",
                  color: "#999999 !important",
                }}
                placeholder="Enter Description (Max 200 words)"
              />
            </Form.Item>
            <div className="crio-word-count">{wordCount} / 200</div>
            <p
              className="op-p op-p-12"
              style={{ maxWidth: "90%", marginTop: "20px" }}
            >
              *Updating the above description will not change the description in
              the organizational level
            </p>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div className="op-label">* Facility and Equipment</div>
          </Col>
          <Col xs={24} sm={24} md={13} lg={13} xl={13}>
            <div className="facility-card">
              <Form.Item
                name="facilityAndEquipmentsId"
                rules={[
                  {
                    required: true,
                    message: "Please select Facility and Equipments",
                  },
                ]}
              >
                <CheckboxGroup
                  style={{
                    width: "100%",
                    maxHeight: "350px",
                    overflowY: "auto",
                  }}
                >
                  <Row>
                    {facilityEquipments.map((el) => {
                      return (
                        <Col span={12} key={el.facilityAndEquipmentId}>
                          <Checkbox
                            value={el.facilityAndEquipmentId}
                            key={el.facilityAndEquipmentId}
                            className="op-checkbox checkbox-facility-site"
                          >
                            {el.facilityAndEquipmentName}
                          </Checkbox>
                        </Col>
                      );
                    })}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <Space>
              <Button
                htmlType="submit"
                className={edited ? "save-pi-button" : "cancel-pi-button"}
                loading={loading}
                disabled={!edited}
              >
                Save
              </Button>
              <Button
                type="secondary"
                onClick={() => {
                  setUpcomingTab(-1);
                  if (edited) {
                    setShowWarning("1");
                  } else {
                    setSiteActiveKey(-1);
                  }
                }}
                className="cancel-pi-button"
                style={{ backgroundColor: "#bfbfbf" }}
              >
                Cancel
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default SiteEditContent;
