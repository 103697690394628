import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { Dropdown, Space, Menu, notification, Skeleton, Modal } from "antd";
import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import LoginService from "../services/loginService";
import UpdateLogoModal from "./UpdateLogoModal";
import OrganizationService from "../services/organizationService";
import { saveOrganization } from "../features/organization";
import { saveAdmin } from "../features/admin";
import { saveAreas } from "../features/areas";
import util from "../../utils/util";
import { saveDirtyForm } from "../features/dirtyForm";

/**
 * A functional component that renders the navigation bar for the application.
 * @returns The navigation bar UI.
 */
const Navbar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let organizationData = useSelector((state) => state.organization);
  const [logo, setLogo] = useState();
  let adminData = useSelector((state) => state.admin);
  let isDirtyForm = useSelector((state) => state.dirtyForm);
  const [style, setStyle] = useState({ display: "none" });
  const [viewNav, setViewNav] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [selectedKey, setSelectedKey] = useState(
    "/" + window.location.pathname.split("/")[1],
  );
  const [modal, contextHolder] = Modal.useModal();
  const confirm = (e) => {
    modal.confirm({
      title: <div className="warning-right-header">Leave Without Saving ?</div>,
      content: (
        <div className="warning-right-body">
          Leaving this page without saving will abandon any unsaved edits
        </div>
      ),
      okButtonProps: {
        className: "warning-footer-right",
        style: { background: "#fff" },
      },
      cancelButtonProps: { className: "warning-footer-left" },
      okText: "Leave Without Saving",
      cancelText: "Cancel",
      onOk: () => {
        if (e.key === "logout") {
          LoginService.logout();
        }
        history.push(e.key);
        setSelectedKey(e.key);
        dispatch(saveDirtyForm(false));
      },
    });
  };

  const items = [
    {
      label: (
        <a
          onClick={() => {
            let e = { key: "/profile" };
            if (isDirtyForm) {
              confirm(e);
            } else {
              history.push(e.key);
              setSelectedKey(e.key);
            }
          }}>
          Profile
        </a>
      ),
      key: "0",
    },
    {
      label: (
        <a
          onClick={() => {
            let e = { key: "logout" };
            if (isDirtyForm) {
              confirm(e);
            } else {
              LoginService.logout();
            }
          }}>
          Logout
        </a>
      ),
      key: "1",
    },
  ];

  /**
   * Uploads an image to the server and updates the organization logo.
   * @param {{fmData}} fmData - The image data to upload.
   */
  const uploadImage = async (fmData) => {
    setLogoLoading(true);
    return await OrganizationService.uploadLogo(fmData)
      .then((res) => {
        let refreshData = Object.assign({}, res.data.data);
        refreshData.organizationLogo = null;
        dispatch(saveOrganization(refreshData));
        dispatch(saveOrganization(res.data.data));
        setLogoLoading(false);
      })
      .catch(() => {
        notification.error({ message: "Uploading logo failed !!!" });
        setLogoLoading(false);
      });
  };

  /**
   * Deletes the logo of the organization by calling the deleteLogo method of the OrganizationService.
   * If the deletion is successful, the organizationData is updated to remove the logo and the updated data is saved.
   * If the deletion fails,  a notification is displayed.
   */
  const deleteImage = async () => {
    return await OrganizationService.deleteLogo()
      .then(() => {
        let refreshData = Object.assign({}, organizationData);
        refreshData.organizationLogo = null;
        dispatch(saveOrganization(refreshData));
      })
      .catch(() => {
        notification.error({ message: "Deleting logo failed !!!" });
      });
  };

  /**
   * useEffect hook that checks if the user has accepted the terms and conditions and redirects
   * them to the home page if they have not.
   * @param {{any}} adminData - The admin data object that contains the user's acceptance of the terms and conditions.
   */
  useEffect(() => {
    if (
      localStorage.getItem("SDIR_STARTER_DATA") &&
      window.location.pathname !== "/home" &&
      adminData &&
      adminData.termsAndConditionChecked !== undefined &&
      adminData.termsAndConditionChecked !== 1
    ) {
      window.location.href = "/home";
    }
  }, [adminData]);

  /**
   * A React useEffect hook that runs when the component mounts and when the organizationData state changes.
   * It checks if the user is authenticated and redirects them to the sessionTimeout page if they are not.
   * If the user is authenticated and the organizationData state is empty, it fetches the organization details,
   * admin details, and all areas from the server and saves them to the Redux store. It also sets the viewNav state
   * and fetches the organization logo if it exists.
   * @param {{Array}} organizationData - An array of organization data.
   */
  useEffect(() => {
    if (
      !localStorage.getItem("SDIR_STARTER_DATA") &&
      window.location.pathname !== "/" &&
      !window.location.pathname.includes("/organization/") &&
      !window.location.pathname.includes("/sessionTimeout")
    ) {
      window.location.href = "/sessionTimeout";
    }
    if (
      localStorage.getItem("SDIR_STARTER_DATA") &&
      organizationData.length === 0
    ) {
      let orgId = util.getOrganizationId();
      OrganizationService.getOrganizationDetails(orgId)
        .then((res) => {
          dispatch(saveOrganization(res.data.data));
          let adminId = util.getAdminId();
          OrganizationService.getOrganizationAdminDetails(adminId)
            .then((resAdmin) => {
              dispatch(saveAdmin(resAdmin.data.data));
            })
            .catch(() => {
              localStorage.removeItem("SDIR_STARTER_DATA");
            });
          OrganizationService.getAllAreas().then((res) => {
            dispatch(saveAreas(res.data.data));
          });
        })
        .catch(() => {
          localStorage.removeItem("SDIR_STARTER_DATA");
        });
    }

    setViewNav(util.checkNavView());
    setLogoLoading(true);
    if (organizationData.length !== 0) {
      OrganizationService.fetchLogo(organizationData.organizationId)
        .then((res) => {
          setLogo(res.data.data);
          setLogoLoading(false);
        })
        .catch(() => {
          setLogoLoading(false);
        });
    } else {
      setLogoLoading(false);
    }
  }, [organizationData]);
  return (
    <>
      {contextHolder}
      <div position="static" className="header">
        <div className="logo">
          {logoLoading ? (
            <Skeleton.Image active />
          ) : logo ? (
            <img
              src={logo}
              alt="Organization Logo"
              onMouseEnter={() => {
                if (
                  adminData &&
                  adminData.length !== 0 &&
                  adminData.termsAndConditionChecked === 1 &&
                  window.location.pathname !== "/home" &&
                  window.location.pathname !== "/"
                ) {
                  setStyle({ display: "flex" });
                }
              }}
            />
          ) : (
            <div
              onMouseEnter={() => {
                if (
                  adminData &&
                  adminData.length !== 0 &&
                  adminData.termsAndConditionChecked === 1 &&
                  window.location.pathname !== "/home" &&
                  window.location.pathname !== "/"
                ) {
                  setStyle({ display: "flex" });
                }
              }}>
              <Skeleton.Image />
            </div>
          )}
          {organizationData.length !== 0 ? (
            <UpdateLogoModal
              style={style}
              setStyle={setStyle}
              uploadLogoData={uploadImage}
              titleText={"Logo"}
              deleteImage={deleteImage}
              deleteOption={organizationData.organizationLogo ? true : false}
              img={organizationData.organizationLogo ? logo : false}
            />
          ) : null}
        </div>
        {viewNav ? (
          <div className="org-nav-name">
            <Menu
              theme="dark"
              mode="horizontal"
              className={"header-nav"}
              selectedKeys={[selectedKey]}
              onClick={(e) => {
                if (isDirtyForm) {
                  confirm(e);
                } else {
                  history.push(e.key);
                  setSelectedKey(e.key);
                }
              }}
              activeKey={[selectedKey]}
              items={[
                {
                  key: "/dashboard",
                  label: "DASHBOARD",
                },
                {
                  key: "/org-profile",
                  label: "ORGANIZATION PROFILE",
                },
                {
                  key: "/site",
                  label: "SITE",
                },
                {
                  key: "/principal-investigator",
                  label: "PRINCIPAL INVESTIGATOR",
                },
              ]}
            />
          </div>
        ) : window.location.pathname.includes("/preview/") ? (
          <div className="org-nav-name-white">PREVIEW</div>
        ) : organizationData ? (
          <div className="org-nav-name">
            {organizationData.organizationName}
          </div>
        ) : null}
        {!window.location.pathname.includes("/organization/") &&
        window.location.pathname !== "/sessionTimeout" ? (
          <div className="profile-nav">
            <Dropdown menu={{ items }} trigger={["click"]}>
              <a
                onClick={(e) => e.preventDefault()}
                style={{ cursor: "pointer" }}>
                <Space>
                  <div className="profile-nav-img">
                    {adminData.length !== 0 ? (
                      adminData.profilePic ? (
                        <img src={adminData.profilePic} alt="Profile" />
                      ) : (
                        <FontAwesomeIcon
                          icon={faUserCircle}
                          style={{ width: "100%", height: "100%" }}
                        />
                      )
                    ) : null}
                  </div>
                  <FontAwesomeIcon icon={faCaretDown} />
                </Space>
              </a>
            </Dropdown>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Navbar;
