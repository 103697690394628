import LoginService from "../js/services/loginService";
const util = {
  /**
   * Checks if the current page is a navigation view or not.
   * @returns {boolean} - true if the current page is a navigation view, false otherwise.
   */
  checkNavView() {
    let url = window.location.pathname;
    if (
      url === "/" ||
      url === "/home" ||
      url === "/setup" ||
      url === "/pageNotFound" ||
      url === "/sessionTimeout" ||
      url.includes("/preview/") ||
      url.includes("/organization/")
    ) {
      return false;
    }
    return true;
  },
  /**
   * Returns a color code based on the status of an item.
   * @param {string} status - The status of the item.
   * @returns {string} - A color code in the format "#RRGGBB".
   */
  getStatusColor(status) {
    if (status === "Public") {
      return "#009245";
    }
    return "#C04040";
  },

  /**
   * Retrieves the organization ID from local storage if it exists, otherwise logs the user out.
   * @returns {string} The organization ID if it exists in local storage.
   * @throws {Error} If the organization ID is not found in local storage, the user is logged out.
   */
  getOrganizationId() {
    if (localStorage.getItem("SDIR_STARTER_DATA")) {
      return JSON.parse(localStorage.getItem("SDIR_STARTER_DATA"))
        .organizationId;
    } else {
      localStorage.removeItem("SDIR_STARTER_DATA");
      window.location.href = "/sessionTimeout";
    }
  },
  /**
   * Retrieves the organization admin ID from local storage.
   * If the ID is not found, logs the user out.
   * @returns {string} The organization admin ID.
   */
  getAdminId() {
    if (localStorage.getItem("SDIR_STARTER_DATA")) {
      return JSON.parse(localStorage.getItem("SDIR_STARTER_DATA"))
        .organizationAdminId;
    } else {
      localStorage.removeItem("SDIR_STARTER_DATA");
      window.location.href = "/sessionTimeout";
    }
  },
  /**
   * Takes in an object containing organization data and returns an array of objects
   * formatted for use in a form.
   * @param {Object} organizationData - the organization data to format
   * @returns {Array} - an array of objects formatted for use in a form
   */
  getOrgProfileFormData(organizationData) {
    let formData = [
      {
        name: "publicDisplayName",
        value: organizationData.publicDisplayName,
      },
      {
        name: "uniformLogo",
        value:
          organizationData.uniformLogo === "UNIFORM"
            ? 2
            : organizationData.uniformLogo === "PARTIALLYUNIFORM"
            ? 1
            : 0,
      },
      {
        name: "uniformDisplayName",
        value:
          organizationData.uniformDisplayName === "UNIFORM"
            ? 2
            : organizationData.uniformDisplayName === "PARTIALLYUNIFORM"
            ? 1
            : 0,
      },
      {
        name: "organizationWebsiteUrl",
        value: organizationData.organizationWebsiteUrl,
      },
      {
        name: "uniformUrl",
        value:
          organizationData.uniformUrl === "UNIFORM"
            ? 2
            : organizationData.uniformUrl === "PARTIALLYUNIFORM"
            ? 1
            : 0,
      },
      {
        name: "uniformDescription",
        value:
          organizationData.uniformDescription === "UNIFORM"
            ? 2
            : organizationData.uniformDescription === "PARTIALLYUNIFORM"
            ? 1
            : 0,
      },
      {
        name: "organizationDescription",
        value: organizationData.organizationDescription,
      },
      {
        name: "primaryEmail",
        value: organizationData.primaryEmail,
      },
    ];
    return formData;
  },
  /**
   * Takes in site data and returns an array of objects that can be used to create a form.
   * @param {Object} siteData - The site data object.
   * @returns {Array} An array of objects that can be used to create a form.
   */
  getSiteFormData(siteData) {
    let facilityAndEquipmentsId = [];
    siteData.facilityAndEquipmentsId.map((el) => {
      facilityAndEquipmentsId.push(el.facilityAndEquipmentId);
    });
    let formData = [
      {
        name: "organizationId",
        value: siteData.organizationId,
      },
      {
        name: "isActive",
        value: siteData.isActive,
      },
      {
        name: "todayViewer",
        value: siteData.todayViewer,
      },
      {
        name: "allTimeViewer",
        value: siteData.allTimeViewer,
      },
      {
        name: "siteLogo",
        value: siteData.siteLogo,
      },
      {
        name: "siteName",
        value: siteData.siteName,
      },
      {
        name: "sipId",
        value: siteData.sipId,
      },
      {
        name: "publicDisplayName",
        value: siteData.publicDisplayName,
      },
      {
        name: "siteWebsiteUrl",
        value: siteData.siteWebsiteUrl,
      },
      {
        name: "publicSiteDescription",
        value: siteData.publicSiteDescription,
      },
      {
        name: "percentageComplete",
        value: siteData.percentageComplete,
      },

      {
        name: "facilityAndEquipmentsId",
        value: facilityAndEquipmentsId,
      },
      {
        name: "houseAddress",
        value: siteData.houseAddress,
      },
      {
        name: "country",
        value: siteData.country,
      },
      {
        name: "city",
        value: siteData.city,
      },
      {
        name: "state",
        value:
          siteData.state && siteData.state.lookupId
            ? siteData.state.lookupId
            : null,
      },
    ];
    return formData;
  },
  /**
   * Returns a payload object with updated site data based on the provided event, states, and siteData.
   * @param {object} e - The event object containing updated site data.
   * @param {array} states - An array of state objects.
   * @param {object} siteData - The original site data object.
   * @returns {object} - The updated payload object.
   */
  getSaveSitePayload(e, states, siteData) {
    let payload = Object.assign({}, siteData);
    payload.city = e.city;
    payload.country = e.country;
    payload.facilityAndEquipmentsId = e.facilityAndEquipmentsId;
    payload.houseAddress = e.houseAddress;
    payload.publicDisplayName = e.publicDisplayName;
    payload.sipId = e.sipId;
    payload.siteWebsiteUrl = e.siteWebsiteUrl;
    payload.publicSiteDescription = e.publicSiteDescription;
    let state = [];
    states.map((el) => {
      if (el.lookupId === e.state) {
        state = el;
      }
    });
    payload.state = state;
    payload.organizationId = payload.organizationId.organizationId;
    return payload;
  },
  /**
   * Returns a payload object for updating an organization's profile based on the given values.
   * @param {Object} values - An object containing the updated values for the organization's profile.
   * @param {Object} organizationData - An object containing the current data for the organization's profile.
   * @returns {Object} - A payload object containing the updated values for the organization's profile.
   */
  getOrgProfilePayload(values, organizationData) {
    let payload = Object.assign({}, organizationData);
    payload.publicDisplayName = values.publicDisplayName;
    payload.uniformLogo =
      values.uniformLogo === 0 || !values.uniformLogo
        ? "NONUNIFORM"
        : values.uniformLogo === 1
        ? "PARTIALLYUNIFORM"
        : "UNIFORM";
    payload.uniformDisplayName =
      values.uniformDisplayName === 0 || !values.uniformDisplayName
        ? "NONUNIFORM"
        : values.uniformDisplayName === 1
        ? "PARTIALLYUNIFORM"
        : "UNIFORM";
    payload.organizationWebsiteUrl = values.organizationWebsiteUrl;
    payload.uniformUrl =
      values.uniformUrl === 0 || !values.uniformUrl
        ? "NONUNIFORM"
        : values.uniformUrl === 1
        ? "PARTIALLYUNIFORM"
        : "UNIFORM";
    payload.uniformDescription =
      values.uniformDescription === 0 || !values.uniformDescription
        ? "NONUNIFORM"
        : values.uniformDescription === 1
        ? "PARTIALLYUNIFORM"
        : "UNIFORM";
    payload.organizationDescription = values.organizationDescription;
    payload.primaryEmail = values.primaryEmail;
    payload.stepOneCompleted = new Date();
    return payload;
  },
  /**
   * Checks if the uploaded logo file is valid by checking its type and size.
   * @param {File} file - The logo file to check.
   * @returns {string | null} - Returns an error message if the file is invalid, otherwise returns null.
   */
  checkLogoError(file) {
    if (
      file.type !== "image/png" &&
      file.type !== "image/jpeg" &&
      file.type !== "image/bmp"
    ) {
      return "Please upload logo in .jpeg, .png or .bmp format...";
    } else if (file.size > 10485760) {
      return "Please upload with in 10MB size...";
    } else {
      return null;
    }
  },
  /**
   * Returns a PI payload object with updated values based on the given parameters.
   * @param {Object} piData - The original payload object.
   * @param {Object} e - An object containing new values to update the payload with.
   * @param {Array} areasOption - An array of therapeutic areas.
   * @param {Array} studiesnumber - An array of study numbers.
   * @param {Array} boardCerts - An array of board certifications.
   * @returns {Object} - The updated payload object.
   */
  getPiPayload(piData, e, areasOption, studiesnumber, boardCerts) {
    let payload = Object.assign({}, piData);
    payload.startingDate = e.startingDate;
    payload.sipId = e.sipId;
    let therapeutic = [];
    e.therapeuticAreaIndicationIds.forEach((el) => {});
    areasOption.forEach((el) => {
      if (e.therapeuticAreaIndicationIds.includes(el.therapeuticName)) {
        therapeutic.push(el.therapeuticId);
      }
    });
    payload.therapeuticAreaIndicationIds = therapeutic;
    studiesnumber.forEach((el) => {
      if (e.numOfStudies === el.value) {
        payload.numOfStudies = el;
      }
    });
    let bCerts = [];
    boardCerts.forEach((el) => {
      if (e.boardCertification.includes(el.value)) {
        bCerts.push(el.lookupId);
      }
    });
    payload.boardCertification = bCerts;
    payload.piDescription = e.piDescription;
    return payload;
  },
  /**
   * Sets the PI profile data for the given PI data, areas option, and board certifications.
   * @param {Object} piData - The PI data object.
   * @param {Array} areasOption - The array of therapeutic areas.
   * @param {Array} boardCerts - The array of board certifications.
   * @returns An array of form data for the PI profile.
   */
  setPiProfile(piData, areasOption, boardCerts) {
    let therapeuticAreaIndicationIds = [];
    if (piData.therapeuticAreaIndicationIds) {
      areasOption.forEach((el) => {
        if (piData.therapeuticAreaIndicationIds.includes(el.therapeuticId)) {
          therapeuticAreaIndicationIds.push(el.therapeuticName);
        }
      });
    }
    let bCerts = [];
    if (piData.boardCertification) {
      boardCerts.forEach((el) => {
        if (piData.boardCertification.includes(el.lookupId)) {
          bCerts.push(el.value);
        }
      });
    }

    let formData = [
      {
        name: "startingDate",
        value: piData.startingDate ? new Date(piData.startingDate) : null,
      },
      {
        name: "sipId",
        value: piData.sipId,
      },
      {
        name: "therapeuticAreaIndicationIds",
        value: therapeuticAreaIndicationIds,
      },
      {
        name: "numOfStudies",
        value: piData.numOfStudies ? piData.numOfStudies.value : null,
      },
      {
        name: "boardCertification",
        value: piData.boardCertification ? bCerts : null,
      },
      {
        name: "piDescription",
        value: piData.piDescription,
      },
    ];
    return formData;
  },
  /**
   * Returns an area object from the given areasOption array that matches the given therapeutic name.
   * @param {string} therapeuticName - The name of the therapeutic area to match.
   * @param {Array} areasOption - The array of area objects to search through.
   * @returns The area object that matches the given therapeutic name.
   */
  getAreaObject(therapeuticName, areasOption) {
    let area = [];
    areasOption.forEach((el) => {
      if (el.therapeuticName === therapeuticName) {
        area = el;
      }
    });
    return area;
  },
  /**
   * Takes in an array of studies and returns a new array with each study object modified
   * to have the correct properties for updating the studies list.
   * @param {Array} studies - the array of studies to modify
   * @returns {Array} - the modified array of studies
   */
  getUpdateStudieslistPayload(studies) {
    let payload = studies.map((el) => {
      let study = Object.assign({}, el);
      study.principalInvestigatorId = study.principalInvestigatorId
        ? study.principalInvestigatorId.principalInvestigatorId
        : null;
      study.therapeuticAreaIndicationId = study.therapeuticAreaIndicationId
        ? study.therapeuticAreaIndicationId.therapeuticId
        : null;
      study.siteId = study.siteId.siteId;
      return study;
    });
    return payload;
  },
};

export default util;
