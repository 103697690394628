import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PageNotFound from "../js/pages/notFoundPage/notFound.page";
import Home from "../js/pages/org-admin/Home";
import Navbar from "../js/components/Navbar";
import Footer from "../js/components/Footer";
import OrgProfile from "../js/pages/dashboard/OrgProfile";
import Dashboard from "../js/pages/dashboard/Dashboard";
import Site from "../js/pages/dashboard/Site";
import PrincipalInvestigator from "../js/pages/dashboard/PrincipalInvestigator";
import OrgProfileSetup from "../js/pages/org-admin/OrgProfileSetup";
import UserProfile from "../js/pages/org-admin/UserProfile";
import PreviewSite from "../js/pages/preview-site/PreviewSite";
import EditSite from "../js/pages/dashboard/EditSite";
import LoginFireBase from "../js/pages/login/LoginFireBase";
import SessionTimeout from "../js/pages/sessionTimeoutPage/SessionTimeOut";

// Renders the application's routes using React Router.\
const Routes = () => {
  const hideNavbarAndFooter =
    window.location.pathname.includes("/organization/");
  return (
    <Router>
      {!hideNavbarAndFooter && <Navbar />}
      <Switch>
        <Route exact path="/organization/:orgId" component={LoginFireBase} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/org-profile" component={OrgProfile} />
        <Route exact path="/profile" component={UserProfile} />
        <Route exact path="/site" component={Site} />
        <Route exact path="/preview/:id" component={PreviewSite} />
        <Route exact path="/site/:id" component={EditSite} />
        <Route exact path="/site/:id/:pId" component={EditSite} />
        <Route exact path="/setup" component={OrgProfileSetup} />
        <Route
          exact
          path="/principal-investigator"
          component={PrincipalInvestigator}
        />
        <Route exact path="/pageNotFound" component={PageNotFound} />
        <Route exact path="/sessionTimeout" component={SessionTimeout} />
        <Redirect to="/pageNotFound" />
      </Switch>
      {!hideNavbarAndFooter && <Footer />}
    </Router>
  );
};

export default Routes;
