import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Skeleton,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import util from "../../utils/util";
import OrganizationService from "../services/organizationService";
import { saveOrganization } from "../features/organization";
import UpdateLogoModal from "./UpdateLogoModal";
import useApplyAllModal from "../hooks/useApplyAllModal";

/**
 * A component that renders a form for editing an organization's profile information.
 * @param {{object}} props - The props object.
 * @param {function} props.setLoaderLoading - A function to set the loading state of the loader.
 * @param {function} props.handleClick - A function to handle a click event.
 * @param {function} props.setStep2 - A function to set the state of step 2.
 * @returns A JSX element that renders a form for editing an organization's profile information.
 */
function EditOrgProfile(props) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [style, setStyle] = useState({ display: "none" });
  let organizationData = useSelector((state) => state.organization);
  const [logo, setLogo] = useState();
  const [confirm, contextHolder] = useApplyAllModal(form, organizationData);
  const [wordCount, setWordCount] = useState(0);

  const handleTextAreaChange = (e) => {
    const { value } = e.target;
    let words = [];
    if (value !== "") {
      words = value.trim().split(/\s+/);
    }
    if (words.length === 200 && value.charAt(value.length - 1) === " ") {
      setWordCount(words.length + 1);
    } else {
      setWordCount(words.length);
    }
  };

  /*
  validates url for website url input field
  */

  const validateUrl = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }

    // Regular expression to validate URL with optional protocol and www
    const urlRegex =
      /^(?:(?:https?|ftp):\/\/)?(?:www\.)?([^\s/$.?#].[^\s]*\.[^\s]{2,})$/i;

    if (urlRegex.test(value.replace("www.", "").replace(".www", ""))) {
      return Promise.resolve();
    }

    return Promise.reject(new Error("Please enter a valid Website URL"));
  };

  const validateWordLimit = (_, value) => {
    if (
      value &&
      value.trim().split(/\s+/).length === 200 &&
      value.charAt(value.length - 1) === " "
    ) {
      return Promise.reject("Maximum word limit exceeded (200 words).");
    }
    if (value && value.trim().split(/\s+/).length > 200) {
      return Promise.reject("Maximum word limit exceeded (200 words).");
    }
    return Promise.resolve();
  };

  // Saves the organization data to the server and updates the state with the new data.
  const saveOrgData = (values) => {
    props.setLoaderLoading(true);
    let payload = util.getOrgProfilePayload(values, organizationData);
    OrganizationService.saveOrganizationDetails(payload)
      .then(() => {
        dispatch(saveOrganization(payload));
        props.handleClick();
        props.setStep2(true);
      })
      .catch(() => {
        notification.error({
          message: "Error while saving Organization profile !!!",
        });
        props.setLoaderLoading(true);
      });
  };

  // Uploads an image to the server and updates the organization logo in the store.
  const uploadImage = async (fmData) => {
    return await OrganizationService.uploadLogo(fmData)
      .then((res) => {
        let refreshData = Object.assign({}, res.data.data);
        refreshData.organizationLogo = null;
        dispatch(saveOrganization(refreshData));
        dispatch(saveOrganization(res.data.data));
      })
      .catch(() => {
        notification.error({ message: "Uploading logo failed !!!" });
      });
  };

  /**
   * Deletes the logo of the organization by calling the deleteLogo method of the OrganizationService.
   * If the deletion is successful, the organizationData is updated to remove the logo and the updated data is saved.
   * If the deletion fails, a notification is displayed.
   */
  const deleteImage = async () => {
    return await OrganizationService.deleteLogo()
      .then((res) => {
        let refreshData = Object.assign({}, organizationData);
        refreshData.organizationLogo = null;
        dispatch(saveOrganization(refreshData));
      })
      .catch((error) => {
        notification.error({ message: "Deleting logo failed !!!" });
      });
  };

  /**
   * useEffect hook that sets the form fields with the organization data and fetches the logo
   * for the organization if it exists.
   */
  useEffect(() => {
    props.setLoaderLoading(true);
    let formData = util.getOrgProfileFormData(organizationData);
    form.setFields(formData);
    if (organizationData.length !== 0) {
      organizationData.organizationDescription &&
        setWordCount(
          organizationData.organizationDescription.trim().split(/\s+/).length,
        );
      OrganizationService.fetchLogo(organizationData.organizationId)
        .then((res) => {
          setLogo(res.data.data);
          props.setLoaderLoading(false);
        })
        .catch((err) => {
          props.setLoaderLoading(false);
        });
    }
  }, [organizationData]);

  return (
    <>
      {contextHolder}
      <div className="body-container-setup">
        <div className="op">
          <Form
            form={form}
            onFinish={saveOrgData}
            onFinishFailed={() => {
              const rootElement = document.querySelector("#root");
              window.scrollTo(0, rootElement.scrollHeight);
            }}>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <div className="op-right-header">Organization Information</div>
                <div className="op-label">Organization Logo</div>
                <div>
                  <div className="op-logo">
                    {logo ? (
                      <img
                        src={logo}
                        alt="Organization Logo"
                        onMouseEnter={() => {
                          setStyle({
                            display: "flex",
                            margin: "20px 10px",
                            height: "-webkit-fill-available",
                            width: "-webkit-fill-available",
                            color: "white",
                          });
                        }}
                      />
                    ) : (
                      <div
                        onMouseEnter={() => {
                          setStyle({
                            display: "flex",
                            margin: "20px 10px",
                            height: "-webkit-fill-available",
                            width: "-webkit-fill-available",
                            color: "white",
                          });
                        }}>
                        <Skeleton.Image />
                      </div>
                    )}
                    <UpdateLogoModal
                      style={style}
                      setStyle={setStyle}
                      uploadLogoData={uploadImage}
                      titleText={"Logo"}
                      deleteImage={deleteImage}
                      deleteOption={
                        organizationData.organizationLogo ? true : false
                      }
                      img={organizationData.organizationLogo ? logo : false}
                    />
                  </div>
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={14}
                lg={14}
                xl={14}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}>
                <div className="op-warning-card-wrapper">
                  <div className="op-warning-card">
                    <div className="op-p">
                      The prefilled fields have all been imported from CRIO.
                      Should any changes need to be made, please update the
                      information accordingly.
                    </div>
                    <br />
                    <br />
                    <div className="op-p">
                      **The changes you make in CRIO Connect will not affect
                      your information in the CRIO site app.
                    </div>
                  </div>
                </div>
                <Form.Item name={"uniformLogo"} valuePropName="checked">
                  <Checkbox
                    className="op-checkbox"
                    onChange={(e) => {
                      confirm("logo", e);
                    }}>
                    Apply this logo to all sites
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <div className="op-label">Organization Name</div>
              </Col>
              <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                <div className="op-label">
                  {organizationData.organizationName}
                </div>
              </Col>
            </Row>
            <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <div className="op-label">Public Display Name</div>
              </Col>
              <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                <Form.Item name={"publicDisplayName"}>
                  <Input
                    className="crio-input"
                    placeholder="Enter Custom Name"
                  />
                </Form.Item>
                <Form.Item name={"uniformDisplayName"} valuePropName="checked">
                  <Checkbox
                    className="op-checkbox"
                    onChange={(e) => {
                      confirm("display name", e);
                    }}>
                    Apply this name to all sites
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <div className="op-label">Organization Website URL</div>
              </Col>
              <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                <Form.Item
                  name={"organizationWebsiteUrl"}
                  rules={[
                    {
                      validator: validateUrl,
                    },
                  ]}>
                  <Input
                    className="crio-input"
                    placeholder="Enter Organization Website URL"
                  />
                </Form.Item>
                <Form.Item name={"uniformUrl"} valuePropName="checked">
                  <Checkbox
                    className="op-checkbox"
                    onChange={(e) => {
                      confirm("URL", e);
                    }}>
                    Apply this URL to all sites
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]} style={{ marginBottom: "20px" }}>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <div className="op-label">Organization Description</div>
              </Col>
              <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                <Form.Item
                  name={"organizationDescription"}
                  rules={[
                    {
                      validator: validateWordLimit,
                    },
                  ]}>
                  <Input.TextArea
                    bordered={false}
                    onChange={handleTextAreaChange}
                    className="crio-input"
                    style={{
                      height: 120,
                      resize: "none",
                      fontSize: "14px",
                      fontFamily: ["Poppins-Light", "Poppins Light", "Poppins"],
                      fontWeight: "200",
                      color: "#999999 !important",
                    }}
                    placeholder="Enter Description (Max 200 words)"
                  />
                </Form.Item>
                <div className="crio-word-count">{wordCount} / 200</div>
                <Form.Item name={"uniformDescription"} valuePropName="checked">
                  <Checkbox
                    className="op-checkbox"
                    onChange={(e) => {
                      confirm("description", e);
                    }}>
                    Apply this description to all sites
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              <Divider className="horizontal-divider-class" />
            </Row>
            <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <div className="op-right-header">Inquiry Information</div>
              </Col>
            </Row>
            <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <div className="op-label">
                  * Primary Email Address
                  <p className="op-p" style={{ margin: "5px 17px" }}>
                    * Email for CRIO to send inquiries
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                <Form.Item
                  name="primaryEmail"
                  rules={[
                    {
                      required: true,
                      message: "Primary Email Address cannot be empty",
                    },
                    {
                      type: "email",
                      message:
                        "Please provide a properly formatted email address",
                    },
                  ]}>
                  <Input
                    className="crio-input"
                    placeholder="Enter Primary Email Address"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <div className="body-container-setup-footer">
        <div className="body-container-setup-footer-inner">
          <Form
            form={form}
            onFinish={saveOrgData}
            onFinishFailed={() => {
              let errList = [];
              form.getFieldsError().forEach((el) => {
                if (el.errors.length !== 0) {
                  errList.push(el);
                }
              });
              const rootElement = document.querySelector("#root");
              window.scrollTo(0, rootElement.scrollHeight);
            }}>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="setup-button">
                Save and Continue <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
}

export default EditOrgProfile;
