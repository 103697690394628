import { Result, Spin } from "antd";
import { useEffect, useState } from "react";

/**
 * A component that checks if the user's session has timed out and displays an appropriate message.
 * If the user's session has timed out, a message
 * will be displayed indicating that their session has timed out and they need to log in again.
 * @returns A React component that displays a message indicating that the user's session has timed out.
 */
const SessionTimeout = () => {
  const [loading, setLoading] = useState(true);

  /**
   * A React useEffect hook that checks if there is starter data in local storage. If there is, it redirects the user to the 404 page. If there isn't, it sets the loading state to false.
   */
  useEffect(() => {
    if (localStorage.getItem("SDIR_STARTER_DATA")) {
      window.location.href = "/pageNotFound";
    } else {
      setLoading(false);
    }
  }, []);
  return loading ? (
    <div className="spin-loader-div-container">
      <Spin size="large" />
    </div>
  ) : (
    <Result
      status="500"
      title="Session Timeout !!"
      subTitle="Sorry, your session has been ended. Please login again."
    />
  );
};
export default SessionTimeout;
