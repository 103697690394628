import { Button, Col, notification, Result, Row, Spin, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import SiteEditTab from "../../components/SiteEditTab";
import { saveAreas } from "../../features/areas";
import { saveBoardCert } from "../../features/boardCert";
import { saveFacility } from "../../features/facility";
import { saveNoOfStudies } from "../../features/noOfStudies";
import { saveAllSites, updateSite } from "../../features/sites";
import { saveStates } from "../../features/states";
import OrganizationService from "../../services/organizationService";
import SitePageService from "../../services/SitePageService";
import LoadingModal from "../../components/LoadingModal";

/**
 * A functional component that renders the site editing page. It retrieves the site information
 * for the given ID and displays it in a tabbed interface. The component also allows the user to
 * switch the site's status between public and unpublished.
 * @returns A JSX element that displays the site editing page.
 */
const EditSite = () => {
  let { id } = useParams();
  let { pId } = useParams();
  const [siteAvailable, setSiteAvailable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [siteActiveKey, setSiteActiveKey] = useState(-1);
  const history = useHistory();
  const updatedSite = useSelector((state) => state.sites);
  const dispatch = useDispatch();

  /**
   * Changes the status of a site to either active or inactive based on the current status.
   * If the site's percentage complete is 100, the status will be switched and the site will be updated.
   * If the site's percentage complete is less than 100, an error notification will be displayed.
   */
  const changeSiteStatus = () => {
    if (updatedSite[0].percentageComplete === 100) {
      setLoading(true);
      SitePageService.switchStatusForSite(updatedSite[0].siteId).then((res) => {
        SitePageService.getSiteInfoForPer(updatedSite[0].siteId)
          .then((res2) => {
            let temp = Object.assign({}, updatedSite[0]);
            temp.percentageComplete = res2.data.data.percentageComplete;
            temp.isActive = res2.data.data.isActive;
            dispatch(updateSite(temp));
            notification.success({
              message: `${temp.siteName} ${
                temp.isActive ? "published" : "unpublished"
              } successfully...`,
            });
            setLoading(false);
          })
          .catch(() => {
            notification.error({
              message: "Something went wrong ! Please refresh the page...",
            });
            setLoading(false);
          });
      });
    } else {
      notification.error({
        message:
          "Please save all mandatory fields to publish " +
          updatedSite[0].siteName,
      });
    }
  };

  /**
   * This useEffect hook is responsible for fetching data from the server and updating the state
   * of the component. It runs only once when the component mounts.
   */
  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      SitePageService.getSiteInfoForEdit(id)
        .then((res) => {
          dispatch(saveAllSites([res.data.data]));
        })
        .catch(() => {
          setSiteAvailable(false);
        });
      OrganizationService.getAllAreas().then((res) => {
        dispatch(saveAreas(res.data.data));
      });
      OrganizationService.getAllBoardCert().then((res) => {
        dispatch(saveBoardCert(res.data.data));
      });
      OrganizationService.getAllNoOfStudies().then((res) => {
        dispatch(saveNoOfStudies(res.data.data));
      });
      OrganizationService.getStates().then((res) => {
        dispatch(saveStates(res.data.data));
      });
      OrganizationService.getFacility().then((res) => {
        dispatch(saveFacility(res.data.data));
      });
    }
  }, []);

  return (
    <>
      <LoadingModal open={loading} />
      <div
        className="body-container"
        style={{ marginBottom: "70px", minHeight: "80vh" }}
      >
        {updatedSite.length === 1 ? (
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <div className="site-edit-p">
                <div className="site-edit-title">Connect Profile</div>
              </div>
              <div className="site-progress-bar-for-single">
                <div className="bar">
                  <div
                    className="bar-fill"
                    style={{
                      backgroundColor:
                        updatedSite[0].percentageComplete > 69
                          ? "#13b639"
                          : updatedSite[0].percentageComplete > 49
                          ? "#e3761c"
                          : "#cccccc",
                      width: `${updatedSite[0].percentageComplete}%`,
                    }}
                  />
                </div>
                <div className="bar-text">
                  {updatedSite[0].percentageComplete}% Complete
                </div>
              </div>
              <div className="site-edit-p">
                <Switch
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  checked={updatedSite[0].isActive ? true : false}
                  onChange={() => changeSiteStatus()}
                />
              </div>
              <p className="site-setup-body-text" style={{ margin: "10px 0" }}>
                By switching this toggle switch, this site can be set to public
                (on) or unpublished (off)
              </p>
            </Col>
            <Col xs={0} sm={0} md={1} style={{ textAlign: "center" }}>
              <div type="vertical" className="vertical-divider-class-2" />
            </Col>
            <Col xs={24} sm={24} md={17} lg={17} xl={17}>
              <div className="op-right-header">
                {updatedSite[0].siteName}
                <i>
                  <p className="op-p">
                    -All fields are required unless stated as optional
                  </p>
                </i>
                <i>
                  <p className="op-p">
                    -Updating any unified fields on a site will only affect that
                    specific site
                  </p>
                </i>
              </div>
              <SiteEditTab
                siteData={updatedSite[0]}
                setSiteActiveKey={setSiteActiveKey}
                fullHeight={true}
                pId={pId ? pId : -1}
              />
            </Col>
          </Row>
        ) : (
          <div className="spinner-wrapper">
            {siteAvailable ? (
              <Spin size={"large"} />
            ) : (
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                  <Button
                    type="primary"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Go Back
                  </Button>
                }
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default EditSite;
