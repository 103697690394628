import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import SiteEditContent from "./SiteEditContent";
import StudyProfileContent from "./StudyProfileContent";
import PrincipalInvestigatorProfileEdit from "./PrincipalInvestigatorProfileEdit";
import { saveDirtyForm } from "../features/dirtyForm";
import { useDispatch } from "react-redux";

/**
 * A component that displays a tabbed interface for editing site data. The component
 * contains three tabs: Site Profile, PI Profile, and Study Profile. Each tab contains
 * a different form for editing the corresponding data. The component also handles
 * warnings when the user tries to navigate away from an edited tab without saving.
 * @param {{Object}} siteData - The data for the site being edited.
 * @param {function} setSiteActiveKey - A function to set the active key for the site.
 * @param {boolean} fullHeight - A boolean indicating whether the component should take up the full height of the container.
 * @returns A tabbed interface for editing site data.
 */
function SiteEditTab({ siteData, setSiteActiveKey, fullHeight, pId }) {
  const [activeTab, setActiveTab] = useState("1");
  const [edited, setEdited] = useState(false);
  const [showWarning, setShowWarning] = useState(-1);
  const [upcomingTab, setUpcomingTab] = useState(-1);
  const dispatch = useDispatch();

  // Exits the current tab and resets the state variables accordingly.
  const exitTab = () => {
    setEdited(false);
    dispatch(saveDirtyForm(false));
    setShowWarning(-1);
    if (upcomingTab === -1) {
      setSiteActiveKey(-1);
      let a = activeTab;
      setActiveTab("4");
      setTimeout(() => {
        setActiveTab(a.toString());
      }, 1000);
    } else {
      setActiveTab(upcomingTab);
    }
    setUpcomingTab(-1);
  };
  useEffect(() => {
    if (pId && pId !== -1) {
      setActiveTab("2");
    }
  }, [pId]);

  return (
    <Tabs
      style={{ maxHeight: fullHeight ? "unset" : "80vh", overflowY: "auto" }}
      defaultActiveKey="1"
      activeKey={activeTab}
      type="card"
      destroyInactiveTabPane
      onChange={(e) => {
        if (edited) {
          setShowWarning(activeTab);
          setUpcomingTab(e);
        } else {
          setActiveTab(e);
        }
      }}
      items={[
        {
          label: `1- Site Profile`,
          key: "1",
          children: (
            <SiteEditContent
              siteData={siteData}
              setActiveTab={setActiveTab}
              setSiteActiveKey={setSiteActiveKey}
              setShowWarning={setShowWarning}
              showWarning={showWarning}
              setEdited={setEdited}
              exitTab={exitTab}
              upcomingTab={upcomingTab}
              edited={edited}
              setUpcomingTab={setUpcomingTab}
            />
          ),
        },
        {
          label: `2- PI Profile`,
          key: "2",
          children: (
            <PrincipalInvestigatorProfileEdit
              siteData={siteData}
              setActiveTab={setActiveTab}
              setEdited={setEdited}
              showWarning={showWarning}
              setShowWarning={setShowWarning}
              exitTab={exitTab}
              upcomingTab={upcomingTab}
              edited={edited}
              setUpcomingTab={setUpcomingTab}
              pId={pId}
            />
          ),
        },
        {
          label: `3- Study Profile`,
          key: "3",
          children: (
            <StudyProfileContent
              siteData={siteData}
              setSiteActiveKey={setSiteActiveKey}
              setEdited={setEdited}
              showWarning={showWarning}
              setShowWarning={setShowWarning}
              exitTab={exitTab}
              upcomingTab={upcomingTab}
              edited={edited}
              setUpcomingTab={setUpcomingTab}
              setActiveTab={setActiveTab}
            />
          ),
        },
      ]}
    />
  );
}

export default SiteEditTab;
